



















































































































































































import { Component, Prop, Vue, Model, Watch } from "vue-property-decorator";
import {
  ExternalProjectActualBudgetItemDto,
  SummaryReportCreateOrUpdateDto,
} from "@/api/appService";
import AbSelect from "@/components/AbSelect/index.vue";
import AbCard from "@/components/AbCard/index.vue";
import api from "@/api";
@Component({
  name: "BudgetSummaryItems",
  components: {
    AbSelect,
    AbCard,
  },
})
export default class BudgetSummaryItems extends Vue {
  @Prop({ required: true })
  budgetForm!: SummaryReportCreateOrUpdateDto;

  @Prop({ required: true })
  specificPlans!: any;

  form: SummaryReportCreateOrUpdateDto = {};

  // 项目预算种类 一级
  projectBudgetCategoryList = [];

  // 项目预算种类 二级
  projectBudgetTypeList = [];

  // 资金来源
  projectBudgetCapitalSourceList = [];

  @Watch("budgetForm", { deep: true })
  getForm(newValue: SummaryReportCreateOrUpdateDto) {
    this.form = newValue;
  }
  @Watch("form", { immediate: true, deep: true })
  getChangeForm(newValue: any) {
    this.$emit("change", newValue);
  }

  created() {
    this.fetchEnum();
    this.form = this.budgetForm;
  }

  fetchEnum() {
    api.enumService
      .getValues({ typeName: "ProjectBudgetCategory" })
      .then((res: any) => {
        this.projectBudgetCategoryList = res;
      });
    api.enumService
      .getValues({ typeName: "ProjectBudgetType" })
      .then((res: any) => {
        this.projectBudgetTypeList = res;
      });
    api.enumService
      .getValues({ typeName: "ProjectBudgetCapitalSource" })
      .then((res: any) => {
        this.$nextTick(() => {
          this.projectBudgetCapitalSourceList = res;
        });
      });
  }
  changeSpecificPlans(e: any, scope: any) {
    if (this.specificPlans && this.specificPlans!.length > 0) {
      this.specificPlans!.map((item: any, index: number) => {
        if (e == item.id) {
          scope.projectSpecificGoalId = item.projectSpecificGoalId;
          scope.itemName = item.title;
        }
      });
    }
  }

  // 预算报价
  calculateMoney(index: number, item: ExternalProjectActualBudgetItemDto) {
    this.form!.externalProjectActualBudgetItems![index].budgetQuotation =
      Number(
        (Number(item.unitPrice ?? 0) * Number(item.number ?? 0)).toFixed(2)
      );
    this.form!.externalProjectActualBudgetItems![index].balance = Number(
      (
        Number(item.budgetQuotation ?? 0) - Number(item.actualBudget ?? 0)
      ).toFixed(2)
    );
    if (item.budgetQuotation && item.actualBudget) {
      this.form!.externalProjectActualBudgetItems![index].actualPercent =
        Number(
          (
            (Number(item.actualBudget ?? 0) /
              Number(item.budgetQuotation ?? 0)) *
            100
          ).toFixed(2)
        );
    }

    this.calculateTotalBudget();
  }

  calculateTotalBudget() {
    let total = 0;
    this.form.externalProjectActualBudgetItems?.map((item: any) => {
      total += Number(item.budgetQuotation);
    });
  }

  getSummary(param: any) {
    const { columns, data } = param;
    const sums: any = [];

    columns.forEach((column: any, index: number) => {
      if (index === 0) {
        sums[index] = "合计";
        return;
      }

      if (!column.property) {
        return;
      }

      const values = data.map((item: any) => Number(item[column.property]));
      if (!values.every((value: any) => isNaN(value))) {
        if (index === 7) {
          sums[index] = values.reduce((prev: any, curr: any) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = sums[index].toFixed(2);
          // sums[index] += '元';
        }
        if (index === 9) {
          sums[index] = values.reduce((prev: any, curr: any) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          this.form.actualBudget = sums[index] = sums[index].toFixed(2);
          // sums[index] += '元';
        }
        if (index === 10) {
          sums[index] = values.reduce((prev: any, curr: any) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          this.form.balance = sums[index] = sums[index].toFixed(2);
          // sums[index] += '元';
        }
        if (index === 11) {
          sums[index] = this.form.actualPercent = sums[7]
            ? Number(((Number(sums[9]) / Number(sums[7])) * 100).toFixed(2))
            : 0;
        }
      }
    });

    return sums;
  }

  // 添加预算信息
  private addRow(index: number) {
    const budgetItem: ExternalProjectActualBudgetItemDto = {
      projectBudgetCategory: undefined,
      projectBudgetType: undefined,
      itemName: undefined,
      projectSpecificPlanId: undefined,
      projectSpecificGoalId: undefined,
      content: undefined,
      unitPrice: undefined,
      number: undefined,
      unit: undefined,
      budgetQuotation: undefined,
      projectBudgetCapitalSource: undefined,
      budgetItemId: undefined,
      actualBudget: 0,
      balance: 0,
      actualPercent: 0,
      reason: undefined,
    };

    this.form!.externalProjectActualBudgetItems! = [
      ...this.form.externalProjectActualBudgetItems!,
      budgetItem,
    ];
  }

  // 删除预算信息
  private deleteRow(index: number) {
    this.form.externalProjectActualBudgetItems!.splice(index, 1);
  }
}
