




















































































































































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";

import {
  OrganizationUnitCreateOrUpdateDto,
  OrganizationUnitDto,
  SummaryReportDto,
  AuditFlowScope,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import OrganizationSelect from "@/components/OrganizationSelect/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import SendNotify from "@/components/SendNotify/index.vue";

@Component({
  name: "RoleList",
  components: {
    SendNotify,
    PagedTableView,
    OrganizationSelect,
    AgileAuditTag,
  },
})
export default class ExternalProjectSummaryReportList extends Vue {
  @Ref() showNotify!: SendNotify;
  queryForm: any = {
    projectName: "",
    orgName: "",
    startDate: "",
    endDate: "",
  };

  partnerOrgList: OrganizationUnitDto[] = [];

  get getNotifyApiUrl() {
    return api.externalProjectSummaryReport.sendNotify;
  }

  created() {
    api.organizationUnit.getAll().then((res: any) => {
      this.partnerOrgList = res.items;
    });
    if (this.$route.query.projectId) {
      this.queryForm.projectId = Number(this.$route.query.projectId);
    }
  }

  // 获取表数据
  fetchData(params: any) {
    return api.externalProjectSummaryReport.getAll(params);
  }

  // 发送通知
  handleShowNotify() {
    (this.showNotify as any).isShowNotify = true;
  }

  // 新建
  handleCreate() {
    this.$router.push({ name: "externalProjectSummaryReportCreate" });
  }

  handleMenu(e: any) {
    switch (e.type) {
      case "detail": {
        this.jumpDetail(e.index, e.item);
        return;
      }
      case "edit": {
        this.handleEdit(e.index, e.item);
        return;
      }
      case "delete": {
        this.handleDelete(e.index, e.item);
        return;
      }
      case "startAudit": {
        this.handleStartAudit(e.index, e.item);
        return;
      }
      case "auditFlow": {
        this.handleAuditFlow(e.index, e.item);
        break;
      }
    }
  }

  // 查看详情
  jumpDetail(index: number, row: SummaryReportDto) {
    this.$router.push({
      name: "externalProjectSummaryReportDetail",
      params: { id: row.id!.toString() },
    });
  }

  // 编辑
  handleEdit(index: number, row: SummaryReportDto) {
    this.$router.push({
      name: "externalProjectSummaryReportEdit",
      params: { id: row.id!.toString() },
    });
  }

  // 删除
  async handleDelete(index: number, row: SummaryReportDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.externalProjectSummaryReport
        .delete({ id: row.id })
        .then((res) => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
    });
  }

  // 提交审核
  async handleStartAudit(item: number, node: SummaryReportDto) {
    // await api.externalProjectSummaryReport.startAudit({
    //   body: {
    //     id: node.id
    //   }
    // });
  }

  handleAuditFlow(index: number, row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.ExternalProjectSummaryReport,
        hostId: row.id,
        auditFlowId: row.auditFlowId,
      },
    });
  }
}
