





















































































































































































































































































import { Component, Ref, Vue } from "vue-property-decorator";
import {
  AttachmentDto,
  AttachmentHostType,
  AuditFlowScope,
  OrganizationUnitDto,
  ProjectSpecificGoalDto,
  SummaryReportDetailDto,
} from "@/api/appService";
import api from "@/api";
import moment from "moment";
import ExportWord from "@/components/DownloadWord/index.vue";
import AttachmentsView from "@/components/AttachmentsView/index.vue";

import AuditBlock, {AuditApi} from "@/components/Audit/AuditBlock.vue";

@Component({
  name: "progressReportDetail",
  components: {
    ExportWord,
    AttachmentsView,
    AuditBlock,
  },
  filters: {
    formatSpecificGoal(
      goalId: number | string,
      effects: ProjectSpecificGoalDto[]
    ) {
      let specificGoal: string | undefined;
      if (effects && effects.length > 0) {
        effects.map((item: ProjectSpecificGoalDto) => {
          if (item.id == goalId) {
            specificGoal = item.effect;
            return;
          }
        });
      }
      return specificGoal;
    },
    formatBudgetCategory(
      budgetCategory: string,
      projectBudgetCategoryList: any[]
    ) {
      let budget: string | undefined;
      if (projectBudgetCategoryList && projectBudgetCategoryList.length > 0) {
        projectBudgetCategoryList.map((item: any) => {
          if (item.value == budgetCategory) {
            budget = item.text;
            return;
          }
        });
      }
      return budget;
    },
    formatEducation(eId: string, educationList: any[]) {
      let name: string | undefined;
      if (educationList && educationList.length > 0) {
        educationList.map((item: any) => {
          if (item.id == eId) {
            name = item.displayName;
            return;
          }
        });
      }
      return name;
    },
  },
})
export default class ProgressReportDetail extends Vue {

  dataId?: number;
  detail: SummaryReportDetailDto = {};
  styles = "";
  selector = "world-content";
  filename = "项目结项自评报告";
  attachmentList: AttachmentDto[] = [];
  hostType = AttachmentHostType.External_Project_Summary_Report;
  projectMembers: any = [
    {
      name: undefined,
      age: undefined,
      sex: undefined,
      educationTypeId: undefined,
      major: undefined,
      qualifications: undefined,
      projectRole: undefined,
      inputPercent: undefined,
    },
  ];

  partners: any = [
    {
      name: undefined,
      resource: undefined,
      affect: undefined,
    },
  ];

  externalProject: any = {
    projectPeriod: undefined,
    objectiveOfMain: undefined,
    serviceGroup: undefined,
  };

  projectSpecificGoals: any = [
    {
      goals: undefined,
      goalsSummary: undefined,
      condition: undefined,
    },
  ];

  budgetUsed: any = [
    {
      subject: undefined,
      planAmount: undefined,
      actualAmount: undefined,
      remark: undefined,
    },
  ];

  problems: any = [
    {
      description: undefined,
      reason: undefined,
      solution: undefined,
    },
  ];

  // 项目预算种类 一级
  projectBudgetCategoryList = [];

  // 项目预算种类 二级
  projectBudgetTypeList = [];

  // 资金来源
  projectBudgetCapitalSourceList = [];

  educationList = [];

  created() {
    this.fetchEnum();
    this.dataId = Number(this.$route.params.id);
    if (this.dataId) {
      this.fetchDetail();
      this.getAttachments();
    }
  }
  get getBudgetSun() {
    let num = 0;
    if (
      this.detail.externalProjectActualBudgetItems &&
      this.detail.externalProjectActualBudgetItems.length > 0
    ) {
      this.detail.externalProjectActualBudgetItems.map((item) => {
        num += Number(item.budgetQuotation);
      });
    }
    return num;
  }
  fetchEnum() {
    api.enumService
      .getValues({ typeName: "ProjectBudgetCategory" })
      .then((res: any) => {
        this.projectBudgetCategoryList = res;
      });
    api.enumService
      .getValues({ typeName: "ProjectBudgetType" })
      .then((res: any) => {
        this.projectBudgetTypeList = res;
      });
    api.enumService
      .getValues({ typeName: "ProjectBudgetCapitalSource" })
      .then((res: any) => {
        this.$nextTick(() => {
          this.projectBudgetCapitalSourceList = res;
        });
      });
    api.dataDictionary
      .getDataDictionaryListByKey({ key: "EducationType" })
      .then((res: any) => {
        this.educationList = res.items;
      });
  }

  // 获取详情
  async fetchDetail() {
    await api.externalProjectSummaryReport
      .getDetail({ id: this.dataId })
      .then((res) => {
        this.detail = { ...res };
        this.projectMembers = JSON.parse(this.detail.projectMemberJson!);
        this.partners = JSON.parse(this.detail.partnersJson!);
        this.projectSpecificGoals = JSON.parse(
          this.detail.projectSpecificGoalJson!
        );
        this.budgetUsed = JSON.parse(this.detail.budgetUsedJson!);
        this.problems = JSON.parse(this.detail.problemsJson!);


      });
  }

  // 获取附件
  async getAttachments() {
    await api.attachmentService
      .getAttachments({
        hostId: this.dataId!.toString(),
        hostType: AttachmentHostType.External_Project_Summary_Report,
      })
      .then((res) => {
        this.attachmentList = res;
      });
  }

  // 返回
  private cancel() {
    this.$router.back();
  }


  get auditApi(){
    return {
      canAudit: api.externalProjectSummaryReport.canAudit,
      userAudit: api.externalProjectSummaryReport.audit} as AuditApi
  }

}
